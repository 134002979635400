'use client';

import { useEffect, useRef } from 'react';

import { PAGES } from '../../../common/pages';
import { useFeatureFlagsContext } from '../../../contexts/FeatureFlagsContext';
import { useRoute } from '../../../hooks/useRoute';

export function LoginRedirection() {
  const isRedirected = useRef(false);

  const featureFlags = useFeatureFlagsContext();
  const isKeycloakLogin = featureFlags?.keycloakAuthentication === true;
  const [, setRoute] = useRoute();

  useEffect(() => {
    if (!isRedirected.current) {
      isRedirected.current = true;

      const pathname = isKeycloakLogin ? PAGES.loginKeycloak.path : PAGES.loginPassword.path;

      setRoute({ pathname });
    }
  }, [isKeycloakLogin, setRoute]);

  return null;
}
